:root {
  --primary: #34AD54;
  --secondary: #FF9933;
  --light: #F6FFF2;
  --dark: #263A4F;
}

$black: #232323;
$background: #ffdf8c;
$gray-line: #f6f6f6;
$orange: #fe5f1e;

$container-width: 90%;

$duration: 0.15s;
