.addproduct__wrapper {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  padding: 20px 0;
}

.root-108 {
  width: 500px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

.root-120, .root-97 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  line-height: 1.5;
}

.addproduct__buttons {
  width: 31%;
  display: flex;
  // flex-wrap: wrap;
  gap: 30px;
  padding-left: 35px;
  flex-direction: column;
  align-items: flex-start;
  // justify-content: center;
}

.addproduct__container {
  width: 100%;
}

.addproduct__infotype {
  // max-width: 500px;
  width: 100%;
  // padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.addproduct {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  // background: #e8eafa;
  
  &__inputs {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
    padding-bottom: 30px;

  }

  &__button {
    display: flex;
    justify-content: center;
  
    &_add {
      width: 360px;
      height: 74px;
      background: #4d9a0e;
      border-radius: 25px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 45px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}

.inputs {
  &__addButton {
    width: 220px;
    padding: 5px;
    border: 1px solid grey;
  }

  &__addInfoBlock {
    display: flex;
    width: 100%;
    padding-top: 30px;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}

.inputs__item {
  width: 500px;
  position: relative;
  padding-top: 0px;
  // padding-bottom: 15px;

  &__name {
    position: relative;
    padding: 5px;
    width: 100%;
    border-bottom: 1px solid #A6A6A6;
    background-color: transparent;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    line-height: 1.5;
    font-size: 14px;
    // color: #000000;
  }

  &__label {
    position: absolute;
    top: -30px;
    left: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: #A6A6A6;
  }

  &__textarea {
    width: 100%;
    height: auto;
    padding: 5px;
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
    color: #000000;
    background-color: transparent;
    border: 1px solid #A6A6A6;
  }
}

.inputs__files {
  width: 100%;

  &_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    line-height: 1.5;
  }

  &_display {
    display: none;
  }

  &__title {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #A6A6A6;
  }

  &__label {
    width: 130px;
    height: 34px;
    border: 2px solid #4d9a0e;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    // font-size: 22px;
    // line-height: 26px;
    color: #4d9a0e;
    cursor: pointer;
  }

  &__view {
    width: 200px;
    margin: 5px auto;
    text-align: center;
  }

  &__view_img {
    width: 200px;
  }
}
