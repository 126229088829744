.main {

  &__about {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // max-width: 1344px;
    margin: 20px auto;
    gap: 2%;
    background-color: #fff;

    @media (max-width: 991px) { 
      justify-content: center;
    }

    &__image {
      flex: 0 0 auto;
      width: 47%;
      border: 5px solid var(--primary);
      margin: 8px;

      & img {
        width: 100%;
      }
    }

    &__info {
      flex: 0 0 auto;
      width: 49%;

      @media (max-width: 991px) { 
        width: 100%;
        padding: 20px;
      }
      
      &__title {
        font-family: "Roboto",sans-serif;
        font-weight: 700;
        line-height: 1.2;
        font-size: 48px;
        color: var(--dark);
        text-align: center;
        padding-top: 10px;

        @media (max-width: 575px) { 
          font-size: 36px;
        }

      }

      &__text {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        line-height: 1.5;
        color: #8D9AA8;
        font-size: 16px;
        padding-right: 5%;

        & p {
          text-indent: 20px;
          margin-top: 10px;
          text-align: justify;
        }
      }
    }

    &_img {

    }
  }

}