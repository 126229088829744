.main {

  &__wrapper {
    width: 100%;

    &__img {
      width: 100%;
    }
  }

  &__title {
    // background-image: url(../../assets/img/main_img.jpg);
    // background-image: url(../../assets/img/main_img2.jpg);
    // background-image: url(../../assets/img/main_img3.png);
    background-image: url(../../../assets/img/main_img4.jpg);
    position: relative;
    background-position: center;
    width: 100%;
    padding: 12% 10%;
    background-size: cover;
    font-family: "Roboto",sans-serif;
    font-weight: 700;
    // line-height: 1.2;
    font-size: 75px;
    color: #fff;

    @media (min-width: 992px) and (max-width: 1199px) { 
      font-size: 65px;
    }

    @media (min-width: 768px) and (max-width: 991px) { 
      font-size: 48px;
    }

    @media (min-width: 576px) and (max-width: 767px) { 
      font-size: 42px;
      padding: 40px;
    }

    @media (max-width: 575px) { 
      font-size: 24px;
      padding: 6% 10%;
    }

    &__text {
      width: 70%;
      // backdrop-filter: blur(2px);
    }

    &__buttons {
      margin-top: 20px;
      display: flex;
      gap: 15px;

      @media (max-width: 575px) { 
        flex-direction: column;
        max-width: 100px;
      }
    }

    &__image {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 35%;
      // object-position: center;
      // object-fit: cover;

      // &_small {
      //   background-image: url(../../../assets/img/main_avatar2.png);
      //   background-size: cover;
      //   background-position: center;
      //   position: absolute;
      //   right: 0px;
      //   bottom: 0px;
      //   width: 35%;
      // }
    }
  }
}