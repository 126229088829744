.main {

  &__wrapper {
    width: 100%;
    flex-grow: 1;
    // flex: 1 0 auto;
    // height: 68vh;
    background: rgba(255,255,255, 0.4);
    // background: url(../../assets/img/building.png) center center fixed;
    // background-size: cover;
    // align-items: center;
    // display: flex;
    // flex-direction: column;
    // text-align: center;
    // justify-content: center;
    // flex-wrap: wrap;
    // gap: 100px;
    // padding: 80px 0px;

    // &__img {
    //   width: 100%;
    // }
    &__title {
      display: flex;
      gap: 10px;
      justify-content: center;
      font-family: "Roboto",sans-serif;
      font-weight: 700;
      line-height: 1.2;
      font-size: 54px;
      color: var(--light);
      text-align: center;
      padding-top: 20px;
      // text-shadow: 3px 5px 6px #000, -6px -7px 6px #000;
      text-shadow: 3px 5px 6px #000, -4px -2px 9px #000;

      @media (max-width: 575px) { 
        font-size: 42px;
      }

      @media (max-width: 450px) { 
        font-size: 32px;
      }

      @media (max-width: 359px) { 
        font-size: 28px;
      }

      &_primary {
        // color: var(--primary);
        color: var(--secondary);
      }

      &_secondary {
        // color: var(--secondary);
        color: var(--primary);
      }
    }

    &__main {
      width: 100%;
      // align-items: center;
      display: flex;
      // text-align: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 100px;
      padding: 40px 10px;
    }
  }

  // &__title {
  //   // background-image: url(../../assets/img/main_img.jpg);
  //   // background-image: url(../../assets/img/main_img2.jpg);
  //   // background-image: url(../../assets/img/main_img3.png);
  //   background-image: url(../../assets/img/main_img4.jpg);
  //   position: relative;
  //   background-position: center;
  //   width: 100%;
  //   padding: 20% 10%;
  //   background-size: cover;
  //   font-family: "Roboto",sans-serif;
  //   font-weight: 700;
  //   // line-height: 1.2;
  //   font-size: 75px;
  //   color: #fff;

  //   &__text {
  //     width: 70%;
  //     // backdrop-filter: blur(2px);
  //   }

  //   &__buttons {
  //     margin-top: 20px;
  //     display: flex;
  //     gap: 15px;
  //   }

  //   &__image {
  //     position: absolute;
  //     right: 0px;
  //     bottom: 0px;
  //     width: 35%;
  //   }
  // }
}