@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import './variables';
@import './libs/normalize';

@import './components/all';

body {
  font-family: 'Roboto', sans-serif;
}


#root {
  //!   поясвляется скролл
  // width: 100vw;
  overflow-x: hidden;
  background: url(../assets/img/backgraund.jpg) center center no-repeat  fixed;
  background-size: cover;

  @media (max-width: 575px) { 
    overflow-x: visible;
    // padding-right: 16px;
  }
}

.wrapper {
  // position: relative;
  display: flex;
  flex-direction: column;

  width: calc(100vw - 80px);
  min-height: 92vh;
  // background-color: #fff;
  // margin: 20px auto;
  // background: url(../assets/img/backgraund.jpg) center center no-repeat  fixed;
  // background-size: cover;
  margin: 15px auto;
  border-radius: 10px;
  max-width: 1400px;
  // padding: 0px 10px;

  // @media (max-width: 645px) {
  //   width: calc(100vw - 30px);
  //   margin-top: 15px;
  // }

  @media (max-width: 575px) { 
    width: 100%;
    margin: 0 auto;
    border-radius: 0px;
    // padding-right: 16px;
  }

  @media (min-width: 576px) and (max-width: 767px) { 
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) { 
    width: calc(100vw - 20px);
  }

  @media (min-width: 992px) and (max-width: 1199px) { 

  }

  @media (min-width: 1200px) { 

  }
}


