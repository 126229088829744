.typecard {
	max-width: 250px;
	width: 100%;
	display: flex;
	// box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
	// box-shadow: 0px 5px 10px 2px rgba(255, 255, 255, 1);
	box-shadow: 1px 0px 10px 8px rgba(255, 255, 255, 1);

	&__card {
		background-color: #F3F6FA;
		border: 1px solid rgba(34, 60, 80, 0.2);
		// padding: 15px 10px;
		padding-bottom: 15px;
		max-width: 250px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		// gap: 10px;
		// min-height: 410px;
		// box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
		transition: all 0.5s ease;

		&:hover {
			transform: scale(1.05);
			cursor: pointer;
		}

		&__image {
			// max-width: 150px;
			max-width: 250px;
			padding: 15px;
			position: relative;

			&_img {
				width: 218px;
				height: 218px;
				position: absolute;
				top: 15px;
				left: 15px;
				// top: 15%;
				// left: 5%;
				// right: 5%;
				// height: 90%;
				background-size: auto;
				background-position: center bottom;
				background-repeat: no-repeat;
				border-radius: inherit;
				-webkit-filter: blur(10px);
				filter: blur(10px);
				// background-image: url(images/main_img4.jpg);
			}
	
			img {
				position: relative;
				width: 100%;
			}
		}

		&__title {
			display: flex;
			flex-direction: column;
			align-items: center;
			flex-grow: 1;
	
			& > h2 {
				font-size: 32px;
				font-weight: 600;
				color: var(--secondary);
				font-weight: 700;
				// font-size: 32px;
				line-height: 1.2em;
			}
	
			& > h3 {
				font-size: 24px;
				color: var(--primary);
				line-height: 1.2em;
				font-weight: 600;
			}
		}	

		&__button {
			padding: 10px 30px;
			border-radius: 5px;
			font-family: "Open Sans", sans-serif;
			font-weight: 600;
			line-height: 1.2;
			font-size: 24px;
			background: var(--primary);
			color: #fff;
			cursor: pointer;
			opacity: 0.9;
	
			&:hover {
				opacity: 1;
			}
		}
	}
}