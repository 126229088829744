.about {
  flex: 1 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px auto;
  // max-width: 1320px;
  gap: 20px;

  &__wrapper {
    position: relative;
    width: 100%;
    
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 48px;
    color: #fff;
    text-align: center;

  }

  &__info {
    display: flex;
    width: 100%;

    @media (max-width: 767px) { 
      flex-direction: column;
    }
  }

  &__form {
    flex: 0 0 auto;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--primary);
    padding: 48px;

    @media (min-width: 992px) and (max-width: 1199px) { 
      padding: 36px;
    }

    @media (min-width: 768px) and (max-width: 991px) { 
      padding: 20px;
    }

    @media (max-width: 767px) { 
      width: 100%;
      padding: 10px;
      gap: 10px;
    }

    &__input {
      padding: 10px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      line-height: 1.5;
      font-size: 16px;
      color: #8D9AA8;
      background-color: #F6FFF2;

      &_btn {
        padding: 15px;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 45px #EDEDED;
        }
      }
    }

  }

  &__adress {
    flex: 0 0 auto;
    width: 45%;
    padding: 48px;
    background-color: var(--secondary);
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 992px) and (max-width: 1199px) { 
      padding: 36px;
    }

    @media (min-width: 768px) and (max-width: 991px) { 
      padding: 20px;
    }

    @media (max-width: 767px) { 
      width: 100%;
      padding: 10px;
      padding-left: 15%;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 24px;
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      line-height: 1.2;
      color: #fff;

      &__title {
        font-size: 32px;

        @media (min-width: 992px) and (max-width: 1199px) { 
          font-size: 28px;
        }

        @media (min-width: 768px) and (max-width: 991px) { 
          font-size: 22px;
        }

        @media (max-width: 767px) { 
          font-size: 28px;
        }
      }

      &__icon {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: var(--primary);

      }

      &__info {
        display: flex;
        flex-direction: column;

        &__title {
          font-size: 20px;

        }

        &__text {
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          line-height: 1.5;
          font-size: 16px;
          // padding: 3px 0;
          color: #fff;
          cursor: default;

          & a {
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            line-height: 1.5;
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }
  }

}




//   &__adress {
//     padding: 20px 0px;

//     &__data {
//       padding: 10px 0px;
//       font-family: "Open Sans", sans-serif;
//       font-weight: 600;
//       line-height: 1.5;
//       font-size: 32px;
//       color: #8D9AA8;

//       &.text {
//         font-size: 24px;
//       }
//     }
//   }

//   &__map {
//     margin-bottom: 20px;
//   }

  
// }