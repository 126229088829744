.productinfo {

  &__description {
    width: 100%;
    margin-top: 50px;
    padding: 35px 0px;
    background: #FAFAFB;

    &_nav {
      display: flex;
      gap: 3px;
      padding: 0 10% 10px 10%;
      font-family: "Roboto",sans-serif;
      font-weight: 500;
      line-height: 1.2;
      font-size: 24px;
      // color: var(--primary);
      // background-color: var(--primary);
      color: #fff;

      @media (max-width: 530px) { 
        font-size: 16px;

      }
    }
    

    &__navitem {
      background-color: var(--primary);
      padding: 10px 20px;
      cursor: pointer;

      @media (max-width: 410px) { 
        padding: 5px 10px;

      }

      &.active {
        background-color: var(--secondary);
        color: #fff;
      }

      &:hover {
        background-color: var(--secondary);
      }
    }

    &_line {
      width: 100%;
      height: 4px;
      background: #E5E8EA;
      opacity: 0.5;
    }

    &_description {
      padding: 21px 10% 0px 10%;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      line-height: 1.5;
      font-size: 16px;
      color: #8D9AA8;
      // text-align: left;
      text-indent: 20px;
      text-align: justify;

      & h3 {
        font-family: "Roboto",sans-serif;
        font-weight: 500;
        line-height: 1.2;
        font-size: 24px;
      }

      & li {
        margin-left: 20px;
        list-style: circle;
        padding-top: 10px;
        text-align: justify;

      }
    }

    // &.delivery {

    // }

    // &.pay {

    // }
  }

}