.cart {
  flex: 1 0 auto;
  width: 100%;
  /* max-width: 820px; */
  /* margin: auto; */
  background-color: #fff;
  padding: 6% 10%;

  @media (max-width: 575px) { 
    width: 100%;
    padding: 6% 3%;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content__title {
    display: flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 28px;

    svg {
      position: relative;
      top: -2px;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      path {
        stroke: #232323;
        stroke-width: 1.9;
      }
    }
  }

  &__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    // @include noselect();

    span {
      display: inline-block;
      margin-left: 7px;
      color: #b6b6b6;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      line-height: 1.5;
      font-size: 16px;
    }

    span,
    svg,
    path {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      svg {
        path {
          stroke: darken($color: #b6b6b6, $amount: 50);
        }
      }
      span {
        color: darken($color: #b6b6b6, $amount: 50);
      }
    }
  }

  .content__items {
    display: block;
  }

  &__item {
    display: flex;
    width: 100%;
    border-top: 1px solid var(--primary);
    padding-top: 30px;
    margin-top: 30px;
    font-family: "Open Sans", sans-serif;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 575px) { 
      justify-content: space-between;
    }

    &-img {
      display: flex;
      align-items: center;
      margin-right: 15px;
      max-width: 100px;
      /* height: 80px; */
      width: 100%;

      @media (max-width: 575px) { 
        max-width: 45px;
        margin-right: 5px;
      }

      // @media (min-width: 768px) and (max-width: 991px) { 
      //   width: auto;
      // }

      img {
        width: 100%;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      font-family: "Open Sans", sans-serif;

      &__description {
        display: flex;
        flex-direction: column;

        &__item {
          font-size: 16px;
          color: #8d8d8d;

          @media (max-width: 575px) { 
            font-size: 10px;
          }
        }
      }

      h3 {
        // font-family: "Open Sans", sans-serif;
        font-weight: 700;
        line-height: 1.5;
        font-size: 28px;

        @media (max-width: 575px) { 
          font-size: 15px;
          line-height: 1.2;
        }
      }

      // p {
      //   font-size: 10px;
      //   color: #8d8d8d;
      // }

    }

    &_countblock {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      text-align: center;
      align-items: center;
    }

    &-count {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 13%;
      gap: 8px;

      & button {
        background: transparent;
      }

      &-minus {
        svg path {
          fill: var(--secondary)
        }
        svg {
          path:first-of-type {
            display: none;
          }
        }

        &:disabled {
          opacity: 0.4;
          border-color: #787878;

          &:active {
            transform: none;
          }

          &:hover {
            background-color: transparent;

            svg path {
              fill: #787878;
            }
          }

          svg path {
            fill: #787878;
          }
        }
      }

      &-plus {
        svg path {
          fill: var(--primary);
        }
      }

      b {
        font-size: 22px;

        @media (max-width: 575px) { 
          font-size: 14px;
        }
      }
    }

    &-price {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 33%;

      @media (max-width: 575px) { 
        width: auto;
      }

      b {
        font-weight: bold;
        font-size: 22px;

        @media (max-width: 575px) { 
          font-size: 14px;
        }
      }
    }

    &-remove {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 4%;

      .button {
        border-color: var(--primary);
        cursor: pointer;
      }

      svg {
        transform: rotate(45deg);

        // path {
        //   // fill: darken($color: var(--primary), $amount: 15);
        // }
      }

      .button {
        svg {
          width: 20px;
          height: 20px;
          position: relative;
        }
        // &:hover,
        // &:active {
        //   // border-color: darken($color: $gray-line, $amount: 80);
        //   // background-color: darken($color: $gray-line, $amount: 80);
        // }
      }
    }
  }

  &__bottom {
    margin: 50px 0;

    &-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      span {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        line-height: 1.5;
        font-size: 22px;

        @media (max-width: 575px) { 
          font-size: 14px;
        }

        &:last-of-type {
          b {
            color: var(--secondary);

            @media (max-width: 575px) { 
              font-size: 18px;
            }
          }
        }
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .go-back-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 210px;

        // border-color: darken($color: $gray-line, $amount: 10);

        span {
          // color: darken($color: $gray-line, $amount: 20);
          font-weight: 500;
          font-weight: 600;
        }

        // &:hover {
        //   // background-color: darken($color: $gray-line, $amount: 90);
        //   // border-color: darken($color: $gray-line, $amount: 90);

        //   // span {
        //   //   // color: $gray-line;
        //   // }
        // }

        svg {
          margin-right: 12px;
          path {
            fill: transparent;
            stroke-width: 2;
          }
        }
      }

      .pay-btn {
        font-size: 16px;
        font-weight: 600;
        width: 210px;
        padding: 16px;
      }
    }
  }

  &__empty {
    flex: 1 0 auto;
    text-align: center;
    background-color: #fff;
    padding: 5% 10%;

    h2 {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      line-height: 1.2;
      font-size: 48px;
      color: var(--dark);

      @media (max-width: 575px) { 
        font-size: 30px;
      }
    }

    p {
      // display: block;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      line-height: 1.5;
      font-size: 24px;
      color: #8D9AA8;
      // text-align: left;
      text-indent: 20px;
      margin-top: 5px;

      @media (max-width: 575px) { 
        font-size: 12px;
      }
    }

    // icon {
    //   position: relative;
    //   top: 2px;
    // }

    img {
      display: block;
      width: 300px;
      margin: 45px auto 60px;

      @media (max-width: 575px) { 
        width: 60%;
      }
    }

    // .button--black {
    //   padding: 12px 0 14px;
    //   width: 230px;
    //   margin: 0 auto;
    //   font-weight: 600;
    //   font-size: 18px;
    // }
    }
  }
