.productitem {

  &__item {
    align-self: stretch;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    flex: 0 0 auto;
    width: 320px;
    // min-height: 475px;
    // height: 359px; 
    // border: 3px solid #F6F7F8;
    border: 3px solid #dff1e0;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 0 45px #EDEDED;
      scale: 1.02;
    }

    &__image {
      width: 100%;
      height: 182px;
      background-color: #F6F7F8;
      display: flex;
      align-items: center;
      text-align: center;
      padding: 10px;

      &_span {
        width: 60px;
        height: 24px;
        display: block;
        margin: 0px 0px 2px 0px;
        padding: 0px 6px 0px 6px;
        position: absolute;
        top: 225px;
        clear: both;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 18px;
        color: #fff;
        text-align: center;
        line-height: 22px;
        // background: #009cdc;
        background: #ff0000;

        &::after {
          content: "";
          border-width: 12px 6px 12px 0px;
          border-style: solid;
          width: 0px;
          height: 0px;
          position: absolute;
          right: -6px;
          top: 0px;
          // border-color: #009cdc transparent #009cdc #009cdc;
          border-color: #ff0000 transparent #ff0000 #ff0000;
        }
      }

      &_img {
        // width: 100%;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

    }

    &__info {
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      text-align: center;
      align-items: center;
      flex-grow: 1;
    }

    &__title {
      font-family: "Roboto",sans-serif;
      font-weight: 700;
      line-height: 1.2;
      font-size: 20px;
      color: #263A4F;
      // text-decoration: underline;
      // color: var(--secondary)
    }

    &__description {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      flex-grow: 1;
    }

    &__text {
      display: flex;
      gap: 5px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      line-height: 1.5;
      font-size: 16px;
      color: #8D9AA8;

      &__secondary {
        font-weight: 700;
        font-size: 18px;
      }
    }

    &__price {
      font-family: "Roboto",sans-serif;
      font-weight: 700;
      line-height: 1.2;
      font-size: 30px;
      color: var(--primary);
    }
  }

  &__cart {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 160px;
    height: 42px;
    // background: #40BFFF;
    // background-image: linear-gradient(var(--primary), var(--secondary));
    background-color: var(--secondary);
    cursor: pointer;
    border-radius: 2px;
    transition: 0.5s;
    margin-bottom: 15px;

    &:hover {
      background-color: var(--primary);
    }

    &:active {
      background-color: var(--primary);
    }

    @media (max-width: 991px) { 
      margin-right: 0;
    }

    &_btn {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      line-height: 1.2;
      font-size: 16px;
      color: #fff;
    }

    // &:hover {
    //   background-color: var(--secondary);
    // }

  }
}