.confirmorder {

  &__wrapper {
    width: 100%;
    padding: 40px;
    height: 100vh;
    display: flex;
    align-items: center;
    position: absolute;
    text-align: center;
    justify-content: center;
    top: 0;
    z-index: 1000;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__container {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 41px 90px 50px 90px;

    @media (max-width: 575px) { 
      padding: 20px;
    }
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 48px;
    color: var(--dark);
    display: flex;
    align-items: flex-end;
    text-transform: capitalize;

    @media (max-width: 575px) { 
      font-size: 38px;
    }
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 30px 0px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 48px;
    
  }

  &__buttons {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: center;
  }

}