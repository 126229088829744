.main__chooseus {
  display: flex;
  flex-direction: column;
  gap: 3%;
  width: 100%;
  margin: 20px auto;
  justify-content: center;


  &__title {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 20px;
    background-color: #34AD54;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 48px;
    color: #fff;
    text-align: center;

    @media (max-width: 767px) { 
      font-size: 36px;
      gap: 12px;
    }

    @media (max-width: 575px) { 
      font-size: 32px;
      flex-wrap: wrap;
    }

    & p {
      color: var(--secondary);
    }
  }

  &__img {
    padding-left: 20px;
    float: right;
    width: 50%;
  
    @media (max-width: 575px) { 
      
    }
  }
  
  &__info {
    padding: 20px 5%;
    background-color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #8D9AA8;
    font-size: 18px;

    &__text {
      // text-indent: 20px;
      margin-top: 10px;
      margin-left: 20px;

      & li {
        list-style: circle;
        padding-top: 10px;
        text-align: justify;

        & strong {
          color: var(--primary);
          font-weight: 700;
          font-size: 20px;
        }

      }

    }
  }
}