.picketfenceinfo {
  flex: 1 0 auto;
  width: 100%;
  // padding: 0 10%;
  margin: 20px auto;
  font-family: 'Poppins', sans-serif;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #fff;
  
  @media (max-width: 575px) { 
    margin: 0px auto;
  }

  &__wrapper {
    width: 100%;
    padding-top: 10px;

  }
  
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    gap: 10px;
    padding: 0px 10%;

    @media (max-width: 991px) { 
      padding: 0px 2%;
    }

    @media (max-width: 575px) { 
      flex-direction: column;
    }
  }
  
  &__imageblock {
    width: 50%;
    padding-top: 20px;

    & > img {

      @media (max-width: 575px) { 
        max-height: 200px;
      }
      
    }

    @media (max-width: 991px) { 
      width: 80%;
    }

    @media (max-width: 575px) { 
      width: 100%;
      padding-top: 0px;
    }
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    // align-items: center;
    // text-align: center;
    // justify-content: center;
    @media (max-width: 991px) { 
      padding-left: 25px;
    }
    
  }

  &__image {
    width: 100%;
    height: 260px;
    object-fit: contain;

    &__colors {
      display: flex;
      flex-wrap: wrap;
      gap: 3px;
      margin-top: 15px;
      width: 100%;
      justify-content: space-between;

      @media (max-width: 575px) { 
        justify-content: start;
        padding-left: 25px;
      }

      & > img {

        @media (max-width: 575px) { 
          max-width: 50px;
          max-height: 60px;
        }
        
      }

    }

    &__item {
      width: 100px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        box-shadow: 0 0 45px #EDEDED;
        scale: 1.02;
      }
    }
  }

  &__titleblock {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 1%;
  }

  &__title {
    font-family: "Roboto",sans-serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 40px;
    color: var(--dark);
    text-align: center;

    @media (max-width: 575px) { 
      font-size: 24px;
    }

    &_btns {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }
  }

  &__rating {
    padding-top: 10px;
    font-size: 20px;
    display: flex;
    gap: 5px;

    & img {
      width: 20px;
    }

    &_review {
      padding-left: 10px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #C1C8CE;
    }
  }

  &__price {
    margin-top: 20px;
    padding: 15px 0px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 30px;
    color: var(--primary);
    border-top: 2px solid #F6F7F8;
    border-bottom: 2px solid #F6F7F8;

    @media (max-width: 575px) { 
      margin-top: 0px;
      padding-bottom: 0px;
    }
  }

  &__instock {
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;
    color: var(--primary);

    @media (max-width: 575px) { 
      margin-bottom: 10px;
    }
    
    &_text {
      color: #8D9AA8;
    }
  }

  &__addinfo {
    padding-top: 5px;
    max-width: 400px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // gap: 40px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;
    color: #8D9AA8;

    &__first {
      width: 100%;
      max-width: 135px;

      @media (max-width: 575px) { 
        font-size: 14px;
      }
    }

    &__selectedcolor {
      position: absolute;
      top: 10px;
      
    }

    &__secondary {
      font-weight: 700;
      font-size: 18px;
      width: 100%;
      text-align: end;

      @media (max-width: 575px) { 
        font-size: 15px;
      }

      &.colorsblock {
        position: absolute;
        top: 50px;
        right: 0px;
        background-color: #fff;
        // padding: 10px;
        // box-shadow: 0 0 0 0 rgba(0,0,0,.2), 0 0 0 0 rgba(0,0,0,.14), 0 0 0 0 rgba(0,0,0,.12);
        transform: scale(1);
        box-shadow: 0 0 5px 5px rgba(34, 60, 80, 0.2);
        transition: box-shadow 0.5s, transform 0.5s;
        z-index: 10;

        // &:hover {
        //   transform: scale(1.1);
        //   box-shadow: 0 0 15px 7px rgba(34, 60, 80, 0.2);
        //   transition: box-shadow 0.5s, transform 0.5s;
        // }
      }

      &.colorsmain {
        width: 100%;
        position: relative;
        cursor: pointer;
      }
    }
  }

  &__cartinfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    padding: 20px 0;
    border-top: 2px solid #F6F7F8;
    border-bottom: 2px solid #F6F7F8;

    @media (max-width: 991px) { 
      gap: 30px;
      justify-content: flex-start;
      flex-direction: column;
    }

    @media (max-width: 575px) { 
      margin-top: 0px;
      gap: 10px;
    }

    &_count {
      display: flex;
    }

    &_block {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 41px;
      height: 42px;
      background: #F6F7F8;
      font-family: "Roboto",sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 19px;
      color: #262626;
      border: 1px solid #e3e3e4;
      border-radius: 2px;
      cursor: pointer;

      &:hover {
        background: #e3e3e4;
      }

      &.nothover {
        cursor: default;

        &:hover {
          background: #F6F7F8;
        }
      }

      &.notActive {
        cursor: default;

        &:hover {
          background: #F6F7F8;
        }
      }
    }
  }

  &__cansel {
    width: 60px;
    cursor: pointer;

    &:active {
      transform: scale(0.9);
    }
  }

  &__cart {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-self: end;
    width: 160px;
    height: 42px;
    // background: #40BFFF;
    // background-image: linear-gradient(var(--primary), var(--secondary));
    background-color: var(--primary);
    cursor: pointer;
    margin-right: 100px;
    border-radius: 2px;
    transition: 0.5s;

    @media (max-width: 991px) { 
      margin-right: 0;
    }

    &_btn {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      line-height: 1.2;
      font-size: 16px;
      color: #fff;
    }

    &:hover {
      background-color: var(--secondary);
    }
    
  }

  &__oneclick {
    width: 160px;
    height: 42px;
    margin-right: 100px;
    align-self: flex-end;
    margin-top: 20px;
    background-color: var(--secondary);
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    line-height: 1.2;
    font-size: 16px;
    color: #fff;
    border-radius: 2px;
    // border: 3px solid var(--secondary);
    transition: 0.5s;

    &:hover {
      background-color: var(--primary);
      // border-color: var(--primary);
    }

    @media (max-width: 991px) { 
      align-self: flex-end;
      // margin-left: 153px;
      margin-top: 0px;
      margin-right: 0px;
    }
  }

  &__consultation {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
    text-align: center;
    align-items: center;
    margin-right: 100px;

    @media (max-width: 991px) { 
      margin-right: 0;
    }

    &_text {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      line-height: 1.2;
      font-size: 20px;
    }

    &_btn {
      width: 280px;
      height: 48px;
      background-color: var(--primary);
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      line-height: 1.2;
      font-size: 20px;
      color: #fff;
      border-radius: 2px;
      // border: 4px solid var(--primary);
      transition: 0.5s;

      &:hover {
        background-color: var(--secondary);
        // border-color: var(--secondary);
      }
    }
  }

  &__colorselect {
    padding: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    // min-width: 300px;

    &:hover {
      background-color: #ececec;
      border-radius: 5px;
      width: 100%;
    }

    &__img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      // background-color: #442f29; //8017
      // background-color: #59191f; //3005
      // background-color: #114232; //6005
      // background-color: #45494e; //7024
    }
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: "Open Sans", sans-serif;
    color: #8D9AA8;

    @media (max-width: 575px) { 
      gap: 5px;
    }

    &__block {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: 575px) { 
        gap: 5px;
      }
    }

    &__item {
      position: relative;
      max-width: 300px;
      width: 100%;
      // font-family: "Open Sans", sans-serif;

      & input,  .selected_colors {
        width: 100%;
        font-weight: 700;
        line-height: 1.5;
        font-size: 18px;
        border: 2px solid #8D9AA8;
        border-radius: 5px;
        min-height: 48px;
        padding: 0px 12px;
        color: #8D9AA8;

        &:focus {
          border: 2px solid #63666b;
        }

        &:focus ~ label {
          top: -10px;
          font-size: 12px;
          // background: white;
          padding: 0px 2px;
        }

        &:valid ~ label {
          top: -10px;
          font-size: 12px;
          // background: white;
          padding: 0px 2px;
        }
      }

      & label {
        position: absolute;
        top: 11px;
        left: 12px;
        // font-family: "Open Sans", sans-serif;
        font-weight: 400;
        line-height: 1.5;
        font-size: 16px;
        color: #8D9AA8;
        background: white;
        cursor: text;
        pointer-events: none;
        transition: .3s cubic-bezier(.25,.8,.5,1);
      }
    }
    
    &__count {
      font-weight: 500;
      line-height: 1.5;
      font-size: 14px;

      & b {
        color: var(--secondary);
        font-size: 20px;
      }
    }

    &__value {
      font-weight: 700;
      line-height: 1.5;
      font-size: 18px;

      & b {
        color: var(--primary);
        font-size: 24px;
      }
    }

  }

}

.for_selected_colors {
  position: absolute;
  top: 11px;
  left: 12px;
  // font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  color: #8D9AA8;
  background: white;
  cursor: text;
  pointer-events: none;
  transition: .3s cubic-bezier(.25,.8,.5,1);

  &.active_color {
    top: -10px;
    font-size: 12px;
    // background: white;
    padding: 0px 2px;
  }
}

.selected_colors {
  cursor: pointer;

  &.warning_item {
    border: 3px solid red;
    animation: 1.0s ease-in-out 0s normal none running trambling-animation;
  }
  // @keyframes trambling-animation {
  //     0%, 50%, 100% {
  //         transform: rotate(0deg);
  //     }
  //     10%, 30% {
  //         transform: rotate(-10deg);
  //     }
  //     20%, 40% {
  //         transform: rotate(10deg);
  //     }
  // }
}

.warning_item {
  border-color: red !important;
  // color: red !important;
  animation: 1.0s ease-in-out 0s normal none running trambling-animation;
}

@keyframes trambling-animation {
  0%, 50%, 100% {
      transform: rotate(0deg);
  }
  10%, 30% {
      transform: rotate(-10deg);
  }
  20%, 40% {
      transform: rotate(10deg);
  }
}
