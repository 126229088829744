.registration {
  position: absolute;
  top: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.5);

  &__block {
    position: relative;
    max-width: 950px;
    width: 100%;
    min-height: 780px;
    background-color: #ffffff;
    border-radius: 50px;
    box-shadow: 2px 3px 11px 5px rgb(0 0 0 / 50%);
    overflow: hidden;
    z-index: 100;
  }

  &__book {
    position: absolute;
    &.one {
      top: -28px;
      left: 35px;
      transform: rotate(-17deg);
      width: 256px;
    }
    &.two {
      top: 231px;
      left: 36px;
      transform: rotate(1deg);
      width: 218px;
      border-radius: 10px;
    }
    &.three {
      top: 449px;
      left: 20px;
      transform: rotate(17deg);
      border-radius: 10px;
    }
  }

  &__container {
    position: absolute;
    top: 0px;
    right: 0px;
    max-width: 640px;
    width: 100%;
    height: 100%;
    background: #e8faf8;
    border-radius: 50px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    box-shadow: 2px 3px 11px 5px rgb(0 0 0 / 50%);
    z-index: 5;
  }

  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }

  &__title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 72px;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &__button {
      margin-top: 40px;
      &_send {
        width: 526px;
        height: 74px;
        background: #B0D8DA;
        border-radius: 25px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 45px;
        color: #FFFFFF;
        
        &.active {
          background: #4d9a0e;
          cursor: pointer;
        }
      }
    }
  }

  &__login-link {
    margin: 40px 0 20px 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
    color: #000000;
  }
}
