.productnavigation {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #F6F7F8;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  // line-height: 1.5;
  font-size: 14px;
  color: #8D9AA8;

  &__item {
    

    &.active {
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: var(--primary);
        // font-weight: 700;
      }
    }

    &.small {
      font-weight: 500;
      font-size: 10px;
    }
  }
}