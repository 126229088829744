.footer {
  width: 100%;
  flex-shrink: 0;

  &__wrapper {
    display: flex;
    background: url(../../assets/img/footer.png) center bottom no-repeat;
    background-size: cover;
    width: 100%;
    padding: 32px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 0 0 auto;
    color: #fff;

      // @media (max-width: 459px) {
      //   font-size: 14px;
      //   padding: 30px 5%;
      // }
    @media (max-width: 575px) { 
      flex-direction: column;
      gap: 10px;
      padding: 20px;
    }

  }

  &__links {
    display: flex;
    gap: 5px;
    color: #fff;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;

    &.active {
      color: var(--secondary)
    }

    &:hover {
      scale: 1.05;
    }

    &__text {
      font-family: "Open Sans", sans-serif;
      font-size: 25px;
      // color: #fff;
      font-weight: 600;
      line-height: 1.5;

      @media (max-width: 575px) { 
        font-size: 20px;
      }
    }

  }

  &__copywrite {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    background-color: #263A4F;
    padding: 10px;
    color: #fff;

    @media (max-width: 575px) { 
      flex-direction: column;
      gap: 12px;
    }
  }
  
  &__item {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    color: #f93;
    // color: #fff;
    
    @media (max-width: 850px) {
      width: 100%;
    }
  }

  &__text {
    color: #fff;
    // letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    line-height: 1.5;
  }
}
