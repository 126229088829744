.btn {
  // display: flex;
  // gap: 5px;
  // align-items: center;
  // text-align: center;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
  transition: .5s;

  &-df {
    display: flex;
    gap: 5px;
    align-items: center;
    text-align: center;
  }

  &-primary {
    background-color: var(--primary);

    &:hover {
      background-color: var(--secondary);
    }
  }

  &-secondary {
    background-color: var(--secondary);

    &:hover {
      background-color: var(--primary);
    }
  }

  &-lg {
    padding: 16px 48px;
    font-size: 20px;

    @media (max-width: 767px) { 
      padding: 12px 32px;
    }

    @media (max-width: 575px) { 
      width: 100%;
      padding: 8px 10px;
      font-size: 12px;
      // max-width: 100px;
    }
  }
}

.btn-primary,
.btn-secondary {
  color: #FFFFFF;
}

.btn-square {
  width: 36px;
  height: 36px;
}

.btn-sm-square {
  width: 28px;
  height: 28px;
}

.btn-lg-square {
  width: 46px;
  height: 46px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}